:root{
    --gap: 20px;
}

.omni-about-container {
    height: auto;
    padding-top: 70px;
}

.about-block {
    align-items: center;
    gap: 20px;
}

.about-dscr {
    flex-direction: column;
    align-items: flex-start;
}

.about-dscr-title {
    font-size: 64px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
}

.about-dscr-txt {
    margin: 0;
    padding-top: 10px;
}

.omnitouch {
    height: 741px;
    justify-content: center;
    align-items: center;
}

.omnitouch-dscr {
    font-size: 64px;
    text-align: center;
    text-transform: uppercase;
}

.solutions {
    padding-top: 140px;
    padding-bottom: 140px;
}

.solutionss-title {
    flex-direction: column;
    padding: 0;
}

.sulutions-list {
    flex-wrap: wrap;
    gap: var(--gap);
    --offsets: 2;
}

.solutions-last-item {
    background: linear-gradient(to right bottom, #273661, #2e2e30);
    width: calc((100% - (var(--gap) * var(--offsets))) / (var(--offsets) + 1));
    height: 543px;
    padding: 30px 30px 54px 30px;
    border-radius: 8px;
    flex-direction: column;
    justify-content: space-between;
    font-size: 24px;
}

.solutions-title-part {
    padding-left: 32vw;
}

.sulutions-item {
    width: calc((100% - (var(--gap) * var(--offsets))) / (var(--offsets) + 1));
    height: 543px;
    padding: 30px 30px 54px 30px;
    background: linear-gradient(to right, #202020, #333333);
    border-radius: 8px;
}

.solutions-item-dscr {
    padding-top: 301px;
    font-size: 24px;
}

@media (max-width: 1272px) {
    .sulutions-list {
        --offsets: 1;
    }

    .about-block  {
        flex-wrap: wrap;
        justify-content: center;
    }

    .about-dscr h2 {
        text-align: center;

    }

    .about-dscr-title {
        font-size: 40px;

    }

    .omni-about-container {
        height: auto;
        padding-top: 40px;
        padding-bottom: 100px;
    }

    .omnitouch-dscr {
        font-size: 40px;
    }

    .solutionss-title {
        font-size: 40px;
    }

    .solutions {
        padding-top: 100px;
    }
}

@media (max-width: 540px) {
    .about-dscr h2 {
        font-size: 24px;
        
    }

    .about-dscr-txt {
        font-size: 18px;
    }

    .omni-about-container {
        height: auto;
        padding-top: 40px;
    }

    .omnitouch-dscr {
        font-size: 22px;
    }

    .omnitouch {
        height: 300px;
    }

    .solutions {
        padding-top: 0;
    }

    .solutionss-title {
        font-size: 22px;
    }

    .solutions-title-part {
        padding-left: 15vw;
    }

    .sulutions-list {
        --offsets: 0;
    }

    .sulutions-item {
        height: auto;
        padding: 20px 20px 13px 20px;
    }

    .solutions-item-dscr {
        padding-top: 150px;
        font-size: 18px;
    }

    .solutions-last-item {
        height: auto;
        font-size: 18px;
        padding: 20px 20px 14px 20px;
    }

    .solutions-last-item div {
        padding-top: 100px;
    }
    .description-btn span {
        font-size: 20px!important;
    }
}

@media (max-height: 450px) {
    .sulutions-item {    
        height: auto;
    }

    .solutions-item-dscr {
        padding-top: 180px;
    }

    .solutions-last-item {
        height: auto;
    }
}


:root{
    --gap: 20px;
}


/* Oragen */

.oragen-descr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 729px;
    text-align: center;
    text-transform: uppercase;
    background-image: url("../img/Logo\ Oragen.png");
    background-repeat: no-repeat;
    background-position: center center;
}


.oragen-database {
    background-image: url("../img/Lines\ Omni.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.oragen-text {
    width: 1172px;
    font-size: 64px;
    font-weight: 400;
    margin: 0;
}

.oragen-adv {
    height: auto;
}

.oragen-adv.add {
    margin-bottom: 100px;
}

.adv-list {
    /* padding-top: 140px; */
    gap: var(--gap);
    --offsets: 1;
    flex-wrap: wrap;
    justify-content: center;
}

.adv-list-item {
    display: flex;
    flex-direction: column;
    width: calc((100% - (var(--gap) * var(--offsets))) / (var(--offsets) + 1));
    height: auto;
    border: 1px solid white;
    padding: 108px 89px 26px 28px;
    justify-content: center;
    transition: 0.3s ease-out;
}

.adv-list-item:hover {
    background-image: linear-gradient(to top, rgb(168,155,125), transparent);
    transition: 0.3s ease-in;
}

.adv-item-title {
    font-size: 36px;
    font-weight: 400;
    margin: 0;
    display: block;
}

.adv-item-descr {
    font-size: 21px;
    font-weight: 400;
    margin: 0;
    padding-top: 14px;
}

.func-container {
    /* height: auto; */
    padding-top: 146px;
    padding-bottom: 100px;

}

.func-list {
    gap: var(--gap);
    --offsets: 1;
    flex-wrap: wrap;
    padding-top: 100px;

}

.func-item-dscr {
    padding-top: 10px;
    flex-direction: column;
}

.func-item {
    /* flex-direction: column; */
    justify-content: flex-end;
    width: calc((100% - (var(--gap) * var(--offsets))) / (var(--offsets) + 1));
    height: 550px;
    position: relative;
}

.func-item-part {
    width: 35vw;
    flex-direction: column;
    justify-content: center;
}

.func-item-title {
    font-size: 60px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
}

.item-dscr-item {
    /* display: flex;
    align-items: center; */
    min-height: 35px;
    padding-top: 10px;
    padding-left: 30px;
    background-image: url("../img/36.svg");
    background-repeat: no-repeat;
    background-position: left;
}

.func-item-img {
    height: 95%;
    width: 100%;
    outline: 1px solid white;
}

.func-item-img::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("../img/Frame\ OR.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 10px 10px;
    z-index: 100;
}

.func-item-img2 {
    height: 95%;
    width: 100%;
    outline: 1px solid white;
}

.func-item-img2::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("../img/Frame\ 1948753118.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -10px 10px;
    z-index: 100;
}

/* .descr-item {
    padding-left: 30px;
    padding-top: 6px;
    background-image: url("../img/36.svg");
    background-repeat: no-repeat;
    background-position: left;
    min-height: 30px;
} */

.stack-container {
    padding-top: 0;
    height: auto;
}

@media (max-width: 1440px) {
    .func-item-part {
        width: 48vw;
    }
}

@media (max-width: 1272px) {
    .func-item-title {
        font-size: 48px;
    }

    .func-item-part {
        width: 48vw;
    }

}

@media (max-width: 992px) {
    .func-list {
        --offsets: 0;
    }

    .func-item-part {
        width: auto;
    }

    .func-item-dscr {
        align-items: center;
    }

    .func-item h2 {
        text-align: center;
    }
    
    .adv-list {
        --offsets: 0;
    }

    .oragen-text {
        font-size: 36px;
    }

    .oragen-descr {
        height: 400px;
    }

    .func-item-img::after {
        background-position: 0 0;
    }

    .func-item-img2::after {
        background-position: 0 0;
    }

}

@media (max-width: 540px) {
    .oragen-text {
        font-size: 24px;
    }

    .oragen-descr {
        height: 400px;
    }

    .adv-list-item {
        padding: 34px 40px 10px 13px;
        height: auto;
    }

    .adv-item-title {
        font-size: 20px!important;
    }

    .adv-item-descr {
        font-size: 14px;
    }

    .func-container {
        padding-top: 50px;
    }

    .func-item {
        height: auto;
    }

    .func-item-img{
        display: none;
    }

    .func-mob-img {
        background-image: url("../img/Oragen\ Dashboard.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        height: 200px;
    }



    .func-mob-img2 {
        background-image: url("../img/Oragen\ Dashboard_.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        height: 300px;
    }

    .func-item-dscr {
        align-items: flex-start;
    }

    .func-item-title {
        font-size: 24px;
    }

    .item-dscr-item {
        font-size: 16px;
    }

    .func-list {
        padding-left: 16px;
        padding-right: 16px;
    }

    .partners-title {
        font-size: 24px;
    }
}
